/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import contact from "../images/contact.png";
import "./contact.css";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { MdOutlineMailOutline } from "react-icons/md";
import NavBar from "../shared/Navbar";
import Footer from "../shared/Footer";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "871144ab-4768-4f73-bfc7-9019872f4174");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      console.log("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div>
      <NavBar />
      {/* Hero Section */}
      <section className="bg-cover hero-section text-white">
        <div className="circle1"></div>
        <div className="circle2"></div>
        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-bs-ride="carousel"
          data-aos="zoom-in"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={contact} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5>
                  <br />
                  <span></span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <div
        className="col-lg-10 mx-auto mt-3 mb-sm-5"
        style={{ backgroundColor: "white" }}
      >
        {/* <h2 className="title text-center" data-aos="zoom-in" style={{ textAlign: 'center', color: 'rgb(240, 48, 131)', paddingTop: '20px' }}>
                    Send Message
                </h2> */}

        <div className="row mx-auto" data-aos="zoom-in">
          {/* Contact Form */}
          <div className="col-lg-8 mt-3">
            <div
              className="contact-form border my-2 py-0 px-0"
              style={{
                background:
                  "linear-gradient(to right bottom, #00ffc3, rgba(255, 255, 255, 0.1))",
              }}
              data-aos="fade-left"
            >
              <h3 style={{ textAlign: "center", padding: "9px" }}>
                Send a Message
              </h3>
              <form className="myForm col-12 pt-3 pb-3" onSubmit={onSubmit}>
                <div className="row mb-3 mx-1">
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control rounded-pill"
                      placeholder="First name *"
                      aria-label="First name"
                      name="firstName"
                      // value={formData.firstName}
                      // onChange={handleChange}
                      required
                    />
                    {errors.firstName && (
                      <small className="text-danger">{errors.firstName}</small>
                    )}
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control rounded-pill"
                      placeholder="Last name *"
                      aria-label="Last name"
                      name="lastName"
                      // value={formData.lastName}
                      // onChange={handleChange}
                      required
                    />
                    {errors.lastName && (
                      <small className="text-danger">{errors.lastName}</small>
                    )}
                  </div>
                </div>
                <div className="row mb-3 mx-1">
                  <div className="col-md-6 mb-2">
                    <input
                      type="email"
                      className="form-control rounded-pill mb-2"
                      placeholder="Email *"
                      aria-label="Email"
                      name="email"
                      // value={formData.email}
                      // onChange={handleChange}
                      required
                    />
                    {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      className="form-control rounded-pill"
                      placeholder="Phone"
                      aria-label="Phone"
                      name="phone"
                      pattern="[0-9]{10}"
                      // value={formData.phone}
                      // onChange={handleChange}
                      required
                    />
                    {errors.phone && (
                      <small className="text-danger">{errors.phone}</small>
                    )}
                  </div>
                </div>
                <div className="row mb-2 mx-1">
                  <div className="col-md-12 mb-2">
                    <textarea
                      className="form-control"
                      rows="6"
                      placeholder="Message"
                      name="message"
                      style={{ height: "196px" }}
                      // onChange={handleChange}
                      // value={formData.message}
                      required
                    ></textarea>
                  </div>
                </div>

                <div className="col-md-6 mx-3 my-4">
                  <button
                    type="submit"
                    className="btn btn-outline-danger Now rounded-pill me-3 px-5"
                  >
                    SEND
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Contact Info */}
          <div className="col-lg-4 col-md-12 mt-3" data-aos="zoom-in">
            <div
              className="contact map2 col-lg-12 mt-2 mb-3 border"
              style={{
                borderRadius: "30px",
                background:
                  "linear-gradient(to right bottom, #00ffc3, rgba(255, 255, 255, 0.1))",
              }}
              data-aos="fade-left"
              data-aos-delay="500"
            >
              <div className="container">
                <div className="row col-md-12">
                  <h3
                    className="text-center"
                    data-aos="zoom-in"
                    style={{ color: "rgb(52, 71, 71)", paddingTop: "0px" }}
                  >
                    Head Office
                  </h3>
                  <div className="about-text mx-3">
                    <div className="icon-text d-flex">
                      <CiLocationOn id="icon" />
                      <h4 style={{ color: "rgb(14, 14, 14)" }}>Address :</h4>
                    </div>
                    <p style={{ paddingLeft: "40px" }}>
                      No-17/9, Lakshmipuram Main Street,
                      <br />
                      Royapettah,
                      <br />
                      Chennai - 600 014.
                    </p>
                    <div className="icon-text d-flex">
                      <MdOutlinePhoneInTalk id="icon" />
                      <h5 style={{ paddingTop: "1px" }}>
                        Tel : 044 - 2811 6003
                      </h5>
                    </div>
                    <div className="icon-text d-flex">
                      <MdOutlineMailOutline
                        id="icon"
                        style={{ marginBottom: "10px" }}
                      />
                      <h5
                        style={{
                          color: "rgb(16, 14, 14)",
                          paddingBottom: "15px",
                          paddingRight: "10px",
                          paddingTop: "10px",
                          fontSize: "16px",
                        }}
                      >
                        Email : nagercoil@ajohnmoris.com
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="contact map2 col-lg-12 mt-2 mb-2 border"
              style={{
                borderRadius: "30px",
                background:
                  "linear-gradient(to right bottom, #00ffc3, rgba(255, 255, 255, 0.1))",
              }}
              data-aos="fade-left"
              data-aos-delay="700"
            >
              <div className="container">
                <div className="row col-md-12">
                  <h3
                    className="text-center"
                    data-aos="zoom-in"
                    style={{ color: "rgb(52, 71, 71)" }}
                  >
                    Camp Office
                  </h3>
                  <div className="about-text mx-3">
                    <div className="icon-text d-flex">
                      <CiLocationOn id="icon" />
                      <h4 style={{ color: "rgb(14, 14, 14)" }}>Address :</h4>
                    </div>
                    <p style={{ paddingLeft: "40px" }}>
                      4th floor,St.Xavior buliding,
                      <br />
                      PWD road,
                      <br />
                      Nagercoil - 629 001.
                    </p>
                    <div className="icon-text d-flex">
                      <MdOutlinePhoneInTalk id="icon" />
                      <h5 style={{ paddingTop: "1px" }}>
                        Tel : 044 - 2811 6003
                      </h5>
                    </div>
                    <div className="icon-text d-flex">
                      <MdOutlineMailOutline
                        id="icon"
                        style={{ marginBottom: "10px" }}
                      />
                      <h5
                        style={{
                          color: "rgb(16, 14, 14)",
                          paddingBottom: "15px",
                          paddingRight: "10px",
                          paddingTop: "10px",
                          fontSize: "16px",
                        }}
                      >
                        Email : Ajmfoundation@gmail.com
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
